'use client';

import * as React from 'react';
import { cn } from '@/lib/utils';
import { useMediaQuery } from '@/lib/mediaquery/use-media-query';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@sapling/ui/components/ui/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@sapling/ui/components/ui/drawer';
import { useDialog } from '@/contexts/dialog-context';

export type DrawerDialogProps = {
  children: React.ReactNode;
  title: string;
  description: string;
  triggerText: string;
  cancelButtonText: string;
};

export function DrawerDialog({
  children,
  title,
  description,
  triggerText,
  cancelButtonText,
  className,
}: DrawerDialogProps & React.ComponentProps<typeof Button>) {
  const { isOpen, handleOpenChange } = useDialog();
  const isDesktop = useMediaQuery('(min-width: 768px)');

  if (isDesktop) {
    return (
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogTrigger asChild>
          <Button variant="outline" className={cn('', className)}>
            {triggerText}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{description}</DialogDescription>
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer
      open={isOpen}
      onOpenChange={handleOpenChange}
      preventScrollRestoration
      disablePreventScroll
    >
      <DrawerTrigger asChild>
        <Button variant="default" className={cn('', className)}>
          {triggerText}
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle className="text-md text-pretty sm:text-lg">
            {title}
          </DrawerTitle>
          <DrawerDescription className="text-pretty text-xs sm:text-sm">
            {description}
          </DrawerDescription>
        </DrawerHeader>
        <div className="overflow-y-visible px-4">{children}</div>
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <Button variant="outline">{cancelButtonText}</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
