'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@sapling/ui/components/ui/form';
import { Input } from '@sapling/ui/components/ui/input';
import { Checkbox } from '@sapling/ui/components/ui/checkbox';
import { cn } from '@sapling/ui/lib/utils';
import { trpc } from '@/lib/trpc';
import { useDialog } from '@/contexts/dialog-context';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

const formSchema = z.object({
  email: z.string().email({
    message: 'Du måste ange en giltig e-postadress.',
  }),
  firstName: z.string().min(1, {
    message: 'Du måste ange ett förnamn.',
  }),
  lastName: z.string().min(1, {
    message: 'Du måste ange ett efternamn.',
  }),
  address: z.string().min(1, {
    message: 'Du måste ange en adress.',
  }),
  postalCode: z.string().min(1, {
    message: 'Du måste ange ett postnummer.',
  }),
  city: z.string().min(1, {
    message: 'Du måste ange en stad.',
  }),
  consent: z.object({
    gdpr: z.boolean().refine((val) => val === true, {
      message: 'Vi behöver ditt samtycke för att kunna skicka ut tidningen.',
    }),
    marketing: z.boolean(),
  }),
});

type SubscribeToMagazineFormProps = {
  siteUrl: string;
  pageName?: string;
};

export function SubscribeToMagazineForm({
  siteUrl,
  className,
  pageName,
}: SubscribeToMagazineFormProps & React.ComponentProps<'form'>) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      city: '',
      consent: {
        gdpr: false,
        marketing: false,
      },
    },
  });

  const path = usePathname();
  const pageUri = new URL(path, siteUrl).toString();

  const { handleOpenChange } = useDialog();
  const mutation = trpc.app.magazine.subscribe.useMutation();

  function onSubmit(values: z.infer<typeof formSchema>) {
    const extraParams = {
      pageUri,
      pageName,
    };

    const mutationData = {
      ...values,
      ...extraParams,
    };

    mutation.mutate(mutationData, {
      onSuccess: () => {
        handleOpenChange(false);
        toast.success('Tack för din prenumeration!', {
          description: 'Du kommer snart att få ett bekräftelsemail.',
        });
      },
      onError: () => {
        toast.error('Ett fel uppstod', {
          description: 'Vänligen försök igen senare.',
        });
      },
    });
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('grid items-start gap-1.5', className)}
      >
        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">E-post</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Din e-postadress"
                    {...field}
                    className="text-base"
                    type="email"
                    autoComplete="email"
                  />
                </FormControl>
                <FormDescription className="text-xs">
                  Vi skickar en bekräftelse till din e-post.
                </FormDescription>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-2">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Förnamn</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Förnamn"
                    {...field}
                    className="text-base"
                    autoComplete="given-name"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Efternamn</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Efternamn"
                    {...field}
                    className="text-base"
                    autoComplete="family-name"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Adress</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Adress"
                    {...field}
                    className="text-base"
                    autoComplete="street-address"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-2">
          <FormField
            control={form.control}
            name="postalCode"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Postnummer</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Postnummer"
                    {...field}
                    className="text-base"
                    autoComplete="postal-code"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Stad</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Stad"
                    {...field}
                    className="text-base"
                    autoComplete="address-level2"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid gap-2 py-2">
          <FormField
            control={form.control}
            name="consent.gdpr"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 pl-2 leading-none">
                  <FormLabel className="text-xs">
                    Jag godkänner att Sapling AB lagrar och behandlar mina
                    personuppgifter.
                  </FormLabel>
                  {/* TODO: Once we have a page with the GDPR policy, we should link to that instead */}
                  {/* <FormDescription className="text-xs">
                    Vi behöver ditt samtycke för att kunna skicka ut tidningen.
                    Du kan läsa mer om hur vi hanterar dina personuppgifter i
                    vår{' '}
                    <Link
                      href="/integritetspolicy"
                      className="font-medium text-sapling-green hover:underline"
                    >
                      integritetspolicy
                    </Link>
                    .
                  </FormDescription> */}
                  {/* <FormDescription className="text-xs">
                    Vi behöver ditt samtycke för att kunna skicka ut tidningen.
                  </FormDescription> */}
                  {/* <FormMessage className="text-xs italic" /> */}
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="consent.marketing"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 pl-2 leading-none">
                  <FormLabel className="text-xs">
                    Jag samtycker till att ta emot marknadsföringskommunikation
                    från Sapling AB.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" className="mt-2 bg-sapling-green text-sm">
          Prenumerera
        </Button>
      </form>
    </Form>
  );
}
